<template>
  <div class="ScalesDossiers bg-white">
    <Spin size="large" fix v-if="adminshowLoading">加载中...</Spin>
    <Spin size="large" fix v-if="customershowLoading">加载中...</Spin>
    <Spin size="large" fix v-if="printLoading">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>正在生成报告...</div>
    </Spin>
    <Row class="px-1">
      <i-col span="24" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1" @click="refresh"></Button>
        <DatePicker
          v-model="dateRange"
          size="large"
          type="daterange"
          placeholder="选择测试日期"
          :start-date="new Date()"
          separator="至"
          format="yyyy-MM-dd"
          style="width: 13rem;margin-right: 0.5rem;"
          @on-change="checkDate"
        ></DatePicker>
        <i-input
          v-model="inputValue"
          size="large"
          placeholder="请输入量表名、姓名或测试编号查询"
          style="width:18.25rem"
          class="el-inline mr-1"
        >
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="HandleSearch"></Button>
        </i-input>
      </i-col>
      <i-col span="24">
        <Table
          border
          ref="dossiersTable"
          :columns="tColumns"
          :data="tData"
          :loading="searching"
          class="mt-1"
          @on-selection-change="checkRow"
        >
          <div slot-scope="{ row }" slot="action">
            <Button
              :disabled="row.isOver == 0"
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="show(row)"
              :loading="adminshowLoading"
            >管理报告</Button>
            <Button
              :disabled="row.isOver == 0"
              type="success"
              size="small"
              style="margin-right: 0.25rem"
              @click="customershow(row)"
            >客户报告</Button>
            <Button size="small" @click="remove(row)">删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "ScalesDossiers",
  data() {
    return {
      batchIds: [],
      reportSetting: {},
      printLoading: false,
      showReprot: false,
      scaleId: -1,
      companyId: -1,
      startTime: "",
      endTime: "",
      inputValue: "",
      searchCompanyList: [],
      companylist: [],
      selectValue: "userName",
      dateRange: "",
      tData: [],
      companyName: "",
      recordTotal: 0,
      loading: false,
      searching: true,
      pageSize: 20,
      pageNum: 1,
      theight: window.innerHeight - 175,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "姓名",
          key: "realName",
          minWidth: 80,
        },
        {
          title: "量表",
          key: "scaleName",
          minWidth: 160,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "性别",
          key: "testerGender",
          render: (h, params) => {
            return h("span", this.myjs.getGender(params.row.testerGender));
          },
          minWidth: 60,
        },
        {
          title: "年龄",
          key: "testerBirthday",
          render: (h, params) => {
            let dt = this.getAge(params.row.testerBirthday);
            return h("span", dt);
          },
          minWidth: 60,
        },
        // {
        //   title: "公司/单位",
        //   key: "companyName",
        //   minWidth: 120,
        //   render: (h, params) => {
        //     return h("span", this.myjs.FormatEmpty(params.row.companyName));
        //   },
        // },
        {
          title: "测评时间",
          key: "testTime",
          minWidth: 100,
          render: (h, params) => {
            let dt = this.myjs.getDateTimeNumCutT(params.row.testTime);
            return h("span", dt);
          },
          sortable: true,
          sortType: "desc",
        },
        {
          title: "测评耗时(时分秒)",
          key: "elapsedTime",
          minWidth: 120,
          render: (h, params) => {
            let dt = this.myjs.formatSeconds(params.row.elapsedTime);
            return h("span", dt);
          },
        },
        {
          title: "测评状态",
          key: "isOver",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            const row = params.row;
            const color = row.isOver === 1 ? "primary" : "warning";
            const text = row.isOver === 1 ? "已完成" : "进行中";
            return h(
              "Tag",
              {
                props: {
                  type: "dot",
                  color: color,
                },
              },
              text
            );
          },
        },
        {
          title: "操作",
          slot: "action",
          width: 220,
          align: "center",
        },
      ],

      customershowLoading: false,
      adminshowLoading: false,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    let selectRegion = localStorage.getItem("SelectScaleTestData");
    let adminId = localStorage.getItem("adminId");
    this.$get("Scale/GetScaleDossiers", {
      startTime: this.startTime,
      endTime: this.endTime,
      scaleId: -1,
      companyId: -1,
      keyword: "",
      pageSize: 20,
      pageNum: 1,
      selectRegion: selectRegion,
      adminId: adminId,
    }).then((res) => {
      this.tData = res.data.responseList;
      this.recordTotal = res.data.total;
      this.searching = false;
      this.$get("Customer/GetCompanyList", {}).then((Company) => {
        this.companylist = Company.data.responseList;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
        this.getPrintSetting();
      });
    });
  },
  methods: {
    batchPrint() {
      if (this.batchIds.length <= 0) {
        this.$Message.error({
          content: "请选择需要操作的数据",
          duration: 3,
        });
        return;
      }
      let scaleIds = "";
      let testIds = "";
      let algKeys = "";
      for (let i = 0; i < this.batchIds.length; i++) {
        scaleIds += this.batchIds[i].scaleId + ",";
        testIds += this.batchIds[i].scaleTestId + ",";
        algKeys += this.batchIds[i].algKey + ",";
      }
      this.$http
        .get("http://localhost:60001/CallServerToPrint", {
          params: {
            calltype: "batchPrint",
            scaleIds: scaleIds,
            testIds: testIds,
            algKeys: algKeys,
            titleContent: this.reportSetting.titleContent,
            pageHeaderContent: this.reportSetting.pageHeaderContent,
            pageFooterContent: this.reportSetting.pageFooterContent,
            baseFieldCollect: this.reportSetting.baseFieldCollect,
            contentStatus: this.reportSetting.contentStatus,
            chartsStatus: this.reportSetting.chartsStatus,
            resultStatus: this.reportSetting.resultStatus,
            adviceStatus: this.reportSetting.adviceStatus,
            logo: this.reportSetting.logoPath,
          },
        })
        .then(
          (result) => {
            this.printLoading = false;
            if (result.body == "处理完毕") {
              this.$Message.success({
                content: "处理完毕!",
                duration: 3,
              });
            }
          },
          (error) => {
            if (error.body == "") {
              this.printLoading = false;
              this.$Modal.confirm({
                title: "打印失败",
                content:
                  "请检查打印工具服务是否未开启，如是请开启服务后重试；如果未下载打印工具请点击下载按钮进行下载",
                okText: "开启",
                cancelText: "下载",
                onOk: () => {
                  this.$Message.info("开启打印工具");
                },
                onCancel: () => {
                  this.$Message.info("下载打印工具");
                },
              });
            }
          }
        );
    },
    checkRow(e) {
      this.batchIds = e;
    },
    getPrintSetting() {
      this.$get("General/GetReportSetting").then((res) => {
        this.reportSetting = res.data.response;
      });
    },
    printReport(row) {
      this.printLoading = true;
      let data = row;
      this.$set(data, "calltype", "print");
      this.$http
        .get("http://localhost:60001/CallServerToPrint", {
          params: {
            calltype: "print",
            testId: row.scaleTestId,
            scaleId: row.scaleId,
            algKey: row.algKey,
            titleContent: this.reportSetting.titleContent,
            pageHeaderContent: this.reportSetting.pageHeaderContent,
            pageFooterContent: this.reportSetting.pageFooterContent,
            baseFieldCollect: this.reportSetting.baseFieldCollect,
            contentStatus: this.reportSetting.contentStatus,
            chartsStatus: this.reportSetting.chartsStatus,
            resultStatus: this.reportSetting.resultStatus,
            adviceStatus: this.reportSetting.adviceStatus,
            logo: this.reportSetting.logoPath,
          },
        })
        .then(
          (result) => {
            this.printLoading = false;
            if (result.body == "处理完毕") {
              this.$Message.success({
                content: "处理完毕!",
                duration: 3,
              });
            }
          },
          (error) => {
            if (error.body == "") {
              this.printLoading = false;
              this.$Modal.confirm({
                title: "打印失败",
                content:
                  "请检查打印工具服务是否未开启，如是请开启服务后重试；如果未下载打印工具请点击下载按钮进行下载",
                okText: "开启",
                cancelText: "下载",
                onOk: () => {
                  this.$Message.info("开启打印工具");
                },
                onCancel: () => {
                  this.$Message.info("下载打印工具");
                },
              });
            }
          }
        );
    },
    previewReport(row) {
      this.printLoading = true;
      let data = row;
      this.$set(data, "calltype", "print");
      this.$http
        .get("http://localhost:60001/CallServerToPrint", {
          params: {
            calltype: "preview",
            testId: row.scaleTestId,
            scaleId: row.scaleId,
            algKey: row.algKey,
            titleContent: this.reportSetting.titleContent,
            pageHeaderContent: this.reportSetting.pageHeaderContent,
            pageFooterContent: this.reportSetting.pageFooterContent,
            baseFieldCollect: this.reportSetting.baseFieldCollect,
            contentStatus: this.reportSetting.contentStatus,
            chartsStatus: this.reportSetting.chartsStatus,
            resultStatus: this.reportSetting.resultStatus,
            adviceStatus: this.reportSetting.adviceStatus,
            logo: this.reportSetting.logoPath,
          },
        })
        .then(
          (result) => {
            this.printLoading = false;
            if (result.body == "处理完毕") {
              this.$Message.success({
                content: "处理完毕!",
                duration: 3,
              });
            }
          },
          (error) => {
            if (error.body == "") {
              this.printLoading = false;
              this.$Modal.confirm({
                title: "打印失败",
                content:
                  "请检查打印工具服务是否未开启，如是请开启服务后重试；如果未下载打印工具请点击下载按钮进行下载",
                okText: "开启",
                cancelText: "下载",
                onOk: () => {
                  this.$Message.info("开启打印工具");
                },
                onCancel: () => {
                  this.$Message.info("下载打印工具");
                },
              });
            }
          }
        );
    },

    remove(row) {
      this.$Modal.confirm({
        title: "提示",
        content: "是否删除?",
        loading: true,
        onOk: () => {
          this.$post("Scale/DeleteScaleTestData", {
            scaleTestId: row.scaleTestId,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.getScaleDossiers();
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
      });
    },
    refresh() {
      this.startTime = "";
      this.endTime = "";
      this.inputValue = "";
      this.pageNum = 1;
      this.pageSize = 20;
      this.getScaleDossiers();
    },
    getAge(birthday) {
      let birthdays = new Date(birthday.replace("T", " "));
      let d = new Date();
      let age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      return age;
    },
    customershow(row) {
      this.customershowLoading = true;
      setTimeout(() => {
        this.customershowLoading = false;
      }, 1500);
      this.$get("Scale/GetReportByCustomer", {
        testId: row.scaleTestId,
      }).then((res) => {
        window.open("https://dxzsadminapi.hxypsy.cn/OutPath/" + res.data.response);
      });
    },
    show(row) {
      this.adminshowLoading = true;
      setTimeout(() => {
        this.adminshowLoading = false;
      }, 1500);
      this.$get("Scale/GetReport", {
        testId: row.scaleTestId,
      }).then((res) => {
        window.open("https://dxzsadminapi.hxypsy.cn/OutPath/" + res.data.response);
      });
    },
    HandleSearch() {
      this.pageNum = 1;
      this.getScaleDossiers();
    },
    clearSelect() {
      this.companyId = -1;
      this.pageNum = 1;
      this.getScaleDossiers();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getScaleDossiers();
    },
    changePage(e) {
      this.pageNum = e;
      this.getScaleDossiers();
    },
    // 选择公司时搜索
    selectCompany(e) {
      this.companyId = e.value;
      this.pageNum = 1;
      this.getScaleDossiers();
    },
    searchCompany(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          const list = this.companylist.map((item) => {
            return {
              value: item.value,
              label: item.label,
            };
          });
          this.searchCompanyList = list.filter(
            (item) => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        }, 200);
      } else {
        this.searchCompanyList = [];
      }
    },
    checkDate(e) {
      this.startTime = e[0];
      this.endTime = e[1];
      this.getScaleDossiers();
    },
    getScaleDossiers() {
      let selectRegion = localStorage.getItem("SelectScaleTestData");
      let adminId = localStorage.getItem("adminId");
      this.searching = true;
      this.$get("Scale/GetScaleDossiers", {
        startTime: this.startTime,
        endTime: this.endTime,
        scaleId: this.scaleId,
        companyId: this.companyId,
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        selectRegion: selectRegion,
        adminId: adminId,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
  },
};
</script>

<style>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
